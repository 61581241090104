import React from 'react';
import './style/App.scss';
import HeaderNav from './components/headerNav';
import FooterNav from './components/footerNav';
// import ScrollTopButton from './components/scrollTopButton';
import News from './components/news';
import Home from './page/home';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import uuid from 'uuid';
import ArchIcon from './images/logo-white.png';
import LocalData from './data/app';
import WorkDetail from './components/workDetail';
import CookiePolicy from './components/cookiePolicy';
// import CookieConsent, { Cookies } from 'react-cookie-consent';
import config from './config';
// import ControlPanel from './components/controlPanel';
import smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';
import { isChrome } from 'react-device-detect';
import { loadReCaptcha } from 'react-recaptcha-google';
import { routes } from './App.route';

export var app = null;

export const App = withRouter(
  class extends React.Component {
    readJsonData = () => {
      const { header, footer } = LocalData;

      header.sitemap.content = Object.assign(
        [],
        header.sitemap.content.map(data => {
          return {
            id: uuid(),
            isLinkClicked: false,
            ...data
          };
        })
      );

      footer.sitemap.content = Object.assign(
        [],
        footer.sitemap.content.map(data => {
          return {
            id: uuid(),
            isLinkClicked: false,
            ...data
          };
        })
      );

      return {
        headerContent: header,
        footerContent: footer
      };
    };

    constructor(props) {
      super(props);
      let data = this.readJsonData();
      this.state = {
        headerContent: data.headerContent,
        footerContent: data.footerContent,
        linkContents: data.linkContents
      };
      app = this;
    }

    componentDidMount() {
      loadReCaptcha();
      // kick off the polyfill!
      smoothscroll.polyfill();

      // handle anchor not in chrome scroll smooth
      this.handleCrossBrowserScrollSmooth();
    }

    componentDidUpdate(prevProps) {
      var currentHash = this.props.location.hash;
      var prevHash = prevProps.location.hash;
      // this.scrollDown(currentHast.replace('#', ''));
      // handle anchor not in chrome && redirect scroll smooth
      // const isChrome =
      //   !!window.chrome &&
      //   (!!window.chrome.webstore || !!window.chrome.runtime);
      // const is_safari = navigator.userAgent.indexOf('Safari') > -1;
      // redirect href from #!/#hash to #hash
      //NOTE: Temp disable
      // let isScroll = /^#!(\/.*)$/.test(prevHash) && /^#/.test(currentHash);
      let isScroll =
        prevHash !== currentHash && currentHash.indexOf('work-number') < 0;

      // let hash = currentHash;
      // TODO: replace setTimeout by state of redirect
      // if (isSafari) {
      //   this.handleRedirectScrollSmooth(isScroll, currentHash);
      // } else {
      //   setTimeout(function() {
      //     this.handleRedirectScrollSmooth(isScroll, currentHash);
      //   }, 800);
      // }
      this.handleRedirectScrollSmooth(isScroll, currentHash);
    }

    handleRedirectScrollSmooth = (isScroll, hash) => {
      if (isScroll) {
        $('html, body').animate(
          {
            scrollTop: $(hash).offset() ? $(hash).offset().top : 0
          },
          500
        );
      }
    };

    handleCrossBrowserScrollSmooth = () => {
      // const isChrome =
      //   !!window.chrome &&
      //   (!!window.chrome.webstore || !!window.chrome.runtime);
      if (!isChrome) {
        $(document).ready(function() {
          // Add smooth scrolling to all links
          $('a').on('click', function(event) {
            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== '' && !/^#!(\/.*)$/.test(this.hash)) {
              // Store hash
              var hash = this.hash;
              $('html, body').animate(
                {
                  scrollTop: $(hash).offset().top
                },
                500,
                function() {
                  // Add hash (#) to URL when done scrolling (default click behavior)
                  window.location.hash = hash;
                }
              );
              return false;
            } else {
              window.scroll({ top: 0, behavior: 'smooth' });
            }
          });
        });
      }
    };

    navTouchHandler = e => {
      let content =
        'header' === e.target.dataset.content
          ? this.state.headerContent
          : this.state.footerContent;
      let linkContent = content.sitemap.content.find(
        linkContent => linkContent.id === e.target.dataset.id
      );
      if (linkContent) linkContent.isLinkClicked = !linkContent.isLinkClicked;
      this.setState({ content });
    };

    footerNavClickHander = ele => {};

    // news
    generateNewContent = props => {
      return <News {...props} />;
    };

    generateWorkDetail = props => {
      return (
        <>
          <div id="header_append"></div>
          <WorkDetail {...props} />
        </>
      );
    };

    generateHome = props => {
      return (
        <Home data={props} scrollDown={this.scrollDown} />
      );
    }

    generateCookiePolicy = props => {
      return (
        <>
          <div id="header_append"></div>
          <CookiePolicy {...props} />
        </>
      );
    };

    scrollDown = scrollTo => {
      let target = document.getElementById(scrollTo);
      if (target) {
        window.scrollTo(0, target.offsetTop);
      }
    };

    handleS3HrefSetup = isRoot => {
      let { headerContent, footerContent } = this.state;
      headerContent.sitemap.content.map(content => {
        content.value = content.value.replace('/#!', '');
        content.value = isRoot ? content.value : '/#!' + content.value;
      });
      footerContent.sitemap.content.map(content => {
        content.value = content.value.replace('/#!', '');
        content.value = isRoot ? content.value : '/#!' + content.value;
      });
    };

    handleRouteMapping = {
      "Home" : this.generateHome,
      "WorkDetail" : this.generateWorkDetail
    };

    render() {
      // handle for s3 bucket static web hosting
      // URL redirect like /#!/path/to/view
      let isHome = this.props.location.pathname === '/';
      this.handleS3HrefSetup(isHome);
      if (/^#!(\/.*)$/.test(this.props.location.hash)) {
        return <Redirect to={RegExp.$1} />;
      }
      
      // init the object from the state with the name
      //const { services, newsContents } = this.state;
      //return React.createElement('div', {className: firstDiv}}, React.createElement('h1', null, 'JSX'));
      const { headerContent, footerContent } = this.state;
      return (
        <div>
          <HeaderNav
            ArchIcon={ArchIcon}
            headerContent={headerContent}
            //touchHandler={this.navTouchHandler}
            //scrollDown={this.scrollDown}
          />
          <div className={`contentWrapper`}>
            <Switch>
              {
                routes.map((route) => {
                  return (
                    <Route 
                      exact = {route.exact}
                      key = {uuid()}
                      path = {route.path}
                      render = {this.handleRouteMapping[route.component]}
                    />
                  )
                })
              }
              <Route 
                exact = {true}
                key = {uuid()}
                path = {"*"}
                render = {this.handleRouteMapping['Home']}
              />
            </Switch>
          </div>
          <footer>
            <FooterNav
              ArchIcon={ArchIcon}
              footerContent={footerContent}
              //touchHandler={this.navTouchHandler}
              //clickHandler={this.footerNavClickHander}
            />
          </footer>
          {/* <SliderTest slides={slides} /> */}
          {/*  <ControlPanel />   */}
          {/* <CookieConsent
          className="cookie-btn"
          location="bottom"
          buttonText="Accept"
          cookieName="EnableCookie"
          style={{ background: 'black' }}
          buttonStyle={{
            background: 'black',
            color: 'white',
            fontSize: '13px',
            fontWeight: 'bold'
          }}
          expires={150}>
          This website uses cookies to enhance the user experience. learn more
          about{' '}
          <a id="cookie-policy-link" href="/#!/cookie-policy">
            our cookie policy
          </a>
        </CookieConsent> */}
        </div>
      );
    }

    redirect(path) {
      this.props.history.push(path);
    }
  }
);
