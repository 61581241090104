import React from 'react';
import './index.scss'


export default class SlideShow extends React.Component {

    render() {
        return (
            <>
                <div className="slideshow">
                    <label className="slide-button" htmlFor="slideCheckbox1">1</label>
                    <input type="radio" id="slideCheckbox1" name="slide" defaultChecked></input>
                    <div className="slide">
                        <div className="slide__content">
                            <h1>Slideshow Concept (No JS)</h1>
                            <p>A pure CSS and HTML slideshow concept.</p>
                            <p>To add or remove slides:</p>
                            <ul>
                                <li>Add a new slide template in the HTML</li>
                                <li>Update the <code>$slide-count</code> SCSS variable</li>
                                <li>Tab colours: Update the <code>$c-slides</code> SCSS variable</li>
                                <li>Slide popout images: Update the <code>$b-slides</code> SCSS variable</li>
                            </ul>
                            <p>Use the tabs below to change slide.</p>
                            <p>Et voila.</p>
                        </div>
                    </div>

                    <label className="slide-button" htmlFor="slideCheckbox2">2</label>
                    <input type="radio" id="slideCheckbox2" name="slide"></input>
                    <div className="slide">
                        <div className="slide__content">
                            <h1>More</h1>
                            <p>More here</p>
                        </div>
                    </div>

                    <label className="slide-button" htmlFor="slideCheckbox3">3</label>
                    <input type="radio" id="slideCheckbox3" name="slide"></input>
                    <div className="slide">
                        <div className="slide__content">
                            <h1>Yet More</h1>
                            <p>Yet more here</p>
                        </div>
                    </div>

                    <label className="slide-button" htmlFor="slideCheckbox4">4</label>
                    <input type="radio" id="slideCheckbox4" name="slide"></input>
                    <div className="slide">
                        <div className="slide__content">
                            <h1>Yet More</h1>
                            <p>Yet more here</p>
                        </div>
                    </div>

                    <label className="slide-button" htmlFor="slideCheckbox5">5</label>
                    <input type="radio" id="slideCheckbox5" name="slide"></input>
                    <div className="slide">
                        <div className="slide__content">
                            <h1>The end</h1>
                            <p>It's over</p>
                        </div>
                    </div> 
                </div>
            </>
        )
    }
}