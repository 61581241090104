import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { app } from '../../App';

export default class HeaderNav extends React.Component {
  constructor(props) {
    super(props);
    //this.touchHandler = this.props.touchHandler;
    this.state = {
      ArchIcon: this.props.ArchIcon,
      headerContent: this.props.headerContent,
      scrollPos: 0,
      isScrollDown: false,
      outOfKv: false
    };
    this.toggleClass = this.toggleClass.bind(this);
  }

  toggleClass = e => {
    e.preventDefault();
    var target =
      e.target.id && e.target.id.indexOf('sitemap') >= 0
        ? e.target.id.replace('sitemap-', '')
        : '';
    if (target) {
      app.redirect(`/#${target}`);
      //app.handleRedirectScrollSmooth(true, `#${target}`);
    }
    let { headerContent } = this.props;
    let temp = headerContent.menuIcon;
    temp.isClicked = !temp.isClicked;
    this.setState({ headerContent });
  };

  scrollDown = ele => {
    this.props.scrollDown(ele.target.dataset.scrollto);
  };

  componentDidMount() {
    window.addEventListener('scroll', this.scrollEvent);
    //window.addEventListener("hashchange", this.scrollEvent, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollEvent);
    // window.removeEventListener("hashchange", this.scrollEvent, false);
  }

  scrollEvent = () => {
    this.displayHeaderHandler();
    this.checkScrollPosition();
  };

  displayHeaderHandler = () => {
    const scrollY = window.scrollY;

    const isScrollDown =
      scrollY > this.state.scrollPos && scrollY > 10 ? true : false;
    this.setState({
      scrollPos: scrollY,
      isScrollDown: isScrollDown
    });
  };

  checkScrollPosition = () => {
    const scrollY = window.scrollY;
    const target =
      document.getElementById('slider') ||
      document.getElementById('header_append');
    if (target) {
      var kvEndPos = target.scrollTop + target.offsetHeight;
      if (scrollY > kvEndPos && kvEndPos > 10) {
        this.setState({
          outOfKv: true
        });
      } else {
        this.setState({
          outOfKv: false
        });
      }
    }
  };

  render() {
    const { isScrollDown, outOfKv } = this.state;
    // fix url update not remove off in scrollIntoView

    return (
      <nav
        className={`headerNav ${isScrollDown ? 'hidden' : ''} ${
          outOfKv ? 'off' : ''
        }`}>
        <Link to="/">
          <img
            className="desktopIcon"
            src={this.state.ArchIcon}
            alt="Arch | The full-service Digital Consultancy in Hong Kong"
          />
        </Link>
        <div
          className={
            'mobileHeader' +
            (this.state.headerContent.menuIcon.isClicked ? ' open' : '')
          }>
          <Link to="/">
            <img
              className="mobileIcon"
              src={this.state.ArchIcon}
              alt="Arch | The full-service Digital Consultancy in Hong Kong"
            />
          </Link>
          <div className="menuIcon" onClick={this.toggleClass}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul
          className={
            'navigation' +
            (this.state.headerContent.menuIcon.isClicked ? ' active' : '')
          }>
          {this.state.headerContent.sitemap.content.map(data => {
            return (
              <li
                key={data.id}
                className={data.isLinkClicked ? 'touch' : ''}
                data-id={data.id}
                data-content={'header'}
                data-scrollto={data.href}
                //onTouchStart={this.touchHandler}
                //onTouchEnd={this.touchHandler}
                //onClick={this.scrollDown}
              >
                <a
                  id={`sitemap-${data.href}`}
                  //href={data.value}
                  onClick={this.toggleClass}>
                  {data.name}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}
