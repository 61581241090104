import React from 'react';
import ScrollNextStepButton from '../../components/scrollNextStepButton';
import './index.scss';

export default class sectionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      scrollTo: this.props.scrollTo
    };
    this.onclickHandler = this.props.onclickHandler;
    this.children = this.props.children;
    this.scrollDown = this.props.scrollDown;
  }

  render() {
    const showScrollNextStepBut = true;
    return (
      <>
        <div
          className="section"
          id={`${this.state.id}`}
          onClick={this.onclickHandler}>
          <div className="sectionWrapper">
            {this.children}
            {this.state.scrollTo && showScrollNextStepBut ? (
              <ScrollNextStepButton
                scrollDown={this.scrollDown}
                scrollTo={this.state.scrollTo}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
}
