import React from 'react';
import './index.scss';

export default class cookiePolicy extends React.Component {

  render() {
    return (
      <>
        <div className="CookieWrapper">
          <h1>Cookies Policy-</h1>
          <br/>
          <p>This website uses cookies and allows third party cookies.</p>
          <p>Cookies are small data files which are placed on your computer by this website and which collect certain personal information about you. Cookies make it easier for you to log on to and use the site during future visits. They also allow us to monitor website traffic and to personalize the content of the site for you.</p>
          <p>We will request your consent to place cookies to gather information about your visits and use of the website to enhance your browsing experience, with the exception of cookies that are used for the sole purpose of carrying out the transmission of communication over the internet or that are strictly necessary to carry out the service requested by you. You can change your website browser settings to reject all cookies if you so wish, but please note that if you do this it may impair the functionality of this website. Cookies will not be used for unsolicited communications."</p>
        </div>
      </>
    );
  }

}