import React from 'react';
import './index.scss';

export default class ServiceItem extends React.Component {

    render() {
        const { service, mouseHandler } = this.props;
        return (
            <th className="serviceItem" 
                onMouseEnter={mouseHandler.bind(this, service.id)} 
                //onMouseLeave={mouseHandler.bind(this, service.id)} 
                onTouchStart={mouseHandler.bind(this, service.id)}
                //onClick={mouseHandler.bind(this, service.id)} change to open new compon
            >
                <div>
                    {
                        service.isHover ?
                            <>
                                <span>{service.title}</span>
                                <span>{service.description}</span>
                            </>
                            : <span>{service.img}</span>
                    }
                </div>
            </th>
        );
    }
}
