import React from 'react';
import './index.scss';
import { Elastic } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'TimelineMax';
import 'animation.gsap';
import 'debug.addIndicators';

function Card(props) {
  return props.value.content.map((cardDetail, index1) => {
    return (
      <div className="card" key={props.value.id + 'cardItem' + index1}>
        <img
          className="card_img"
          src={require(`../../images/${cardDetail.img}.png`)}
          alt={cardDetail.alt}
        />
        <h2 className="card_title">{cardDetail.title}</h2>
        <ul className="card_description">
          {cardDetail.descriptions.map((description, index2) => {
            return (
              <li key={props.value.id + 'card_description' + index2}>
                {description}
              </li>
            );
          })}
        </ul>
      </div>
    );
  });
}

function scrollAnimation() {
  let controller = new ScrollMagic.Controller();
  // eslint-disable-next-line no-undef
  let tl = new TimelineMax();
  tl.staggerFrom('.card_img', 2, {
    scale: 0,
    cycle: {
      y: [-50, 50]
    },
    ease: Elastic.easeOut,
    stagger: {
      from: 'center',
      amount: 0.25
    }
  });

  // eslint-disable-next-line no-undef
  let t2 = new TimelineMax();
  t2.from('.card_title', 2, { x: -300, opacity: 0 });

  // eslint-disable-next-line no-undef
  let t3 = new TimelineMax();
  t3.from('.card_description', 1, {
    scale: 0,
    stagger: {
      from: 'center',
      amount: 0.75
    }
  });

  if (document.getElementById('service')) {
    let scene = new ScrollMagic.Scene({
      triggerElement: '#service',
      triggerHook: 0
    })
      .setTween(tl)
      .addTo(controller);

    let scene2 = new ScrollMagic.Scene({
      triggerElement: '#service',
      duration: '50%',
      triggerHook: 0.5
    })
      .setTween(t2)
      .addTo(controller);

    let scene3 = new ScrollMagic.Scene({
      triggerElement: '#service',
      triggerHook: 0.5
    })
      .setTween(t3)
      .addTo(controller);
  }
}

export default class cardItem extends React.Component {
  componentDidMount() {
    scrollAnimation();
  }

  render() {
    const { cardDetail } = this.props;
    return (
      <>
        <div className="wrapper" id="card-item">
          <Card value={cardDetail} />
        </div>
      </>
    );
  }
}
