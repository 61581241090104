import React from 'react';
import ServiceItem from '../serviceItem';
import './index.scss';

export default class ServiceBoard extends React.Component {
  // looping the render element
  generateContent = () => {
    const { services, service_row, mouseHandler } = this.props;
    let col = Math.round(services.length / service_row);
    let row_content = [];
    for (let i = 0; i < service_row; i++) {
      let col_content = [];
      for (let j = 0; j < col; j++) {
        let service = services[j + i * col];
        if (service) {
          col_content.push(
            <ServiceItem
              key={service.id}
              service={service}
              mouseHandler={mouseHandler}
            />
          );
        }
      }
      //row_content.push(<tr key={"row" + i} className="serviceRow">{col_content}</tr>);
      row_content.push(<tr className="serviceRow">{col_content}</tr>);
    }
    return row_content;
  };

  /* onMouseEnter onMouseLeave*/

  render() {
    return (
      <div className="service-location">
        <table className="service-table">
          <tbody>{this.generateContent()}</tbody>
        </table>
      </div>
    );
  }
}

// for the DOM rendor the component
// export default ServiceBoard;
