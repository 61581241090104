import React from 'react';
import './index.scss';
import 'swiper/css/swiper.css';
import { Link } from 'react-router-dom';
import uuid from 'uuid';
import Swiper from 'react-id-swiper';

export default class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: props.slides || []
    };
  }

  componentWillUnmount = () => {};

  componentDidMount = () => {};

  render() {
    const { slides } = this.state;
    const params = {
      slidesPerView: 3,
      grabCursor: true,
      loop: true,
      spaceBetween: 30,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        320: {
          slidesPerView: 1.5,
          spaceBetween: 10
        }
      }
    };

    return (
      <div className="worksSlider">
        <h2 className="stroke">Featured Works-</h2>
        <div className="work-slider-wrapper">
          <Swiper {...params}>
            {slides.map((data, index) => {
              var formatIndex = index < 9 ? `0${index + 1}` : index + 1;
              return (
                <div key={uuid()}>
                  <div className="slider-image">
                    <img src={data.img} alt={`Arch | Featured Works - ${data.title}`} />
                  </div>
                  <Link to={`/#!/work-number/${formatIndex}/${data.link}`}>
                    <div className="slider-content">
                      <div className="slider-index">
                        <span>{formatIndex}</span>
                      </div>
                      <h3 className="slider-title">{data.title}</h3>
                    </div>
                  </Link>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }
}
