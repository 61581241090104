import React from 'react';
import './index.scss';
import plane from './paper-plane1.png';
import ScrollMagic from 'scrollmagic';
import 'TimelineLite';
import 'TweenLite';
import { Power1 } from 'gsap';

function triggerFlight(flightPath) {
  let controller = new ScrollMagic.Controller();

  if (document.getElementById('shareAndJoinUs')) {
    // eslint-disable-next-line no-undef
    let tween = new TimelineLite();

    tween.add(
      // eslint-disable-next-line no-undef
      TweenLite.to('.flighting-plane', 2.5, {
        bezier: flightPath,
        ease: Power1.easeInOut
      })
    );

    let scene = new ScrollMagic.Scene({
      triggerElement: '#shareAndJoinUs'
    })
      .setTween(tween)
      .addTo(controller);
    //.addIndicators(); // for testing
  }
}

export default class FlightPlane extends React.Component {
  constructor(props) {
    super(props);
    let flightPath = {
      curviness: 1.25,
      autoRotate: false,
      values: [
        { x: 100, y: -20 },
        { x: window.innerWidth / 2 + 100, y: 200 }
      ]
    };

    this.state = {
      flightPath: flightPath
    };
  }

  componentDidMount() {
    triggerFlight(this.state.flightPath);
  }

  render() {
    return (
      <>
        <div className="flighting-container">
          <img className="flighting-plane" src={plane} alt="plane" />
        </div>
      </>
    );
  }
}
