import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-google';
import config from '../../config';

class ReCaptchav2 extends Component {
  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    if (this.captcha) {
      this.captcha.reset();
    }
  }

  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset();
    }
  }

  verifyCallback(recaptchaToken) {
    this.props.getResponse(recaptchaToken);
  }

  render() {
    var key = config.recaptchaId;
    return (
      <div>
        <ReCaptcha
          ref={el => {
            this.captcha = el;
          }}
          size="normal"
          render="explicit"
          sitekey={key}
          data-badge="inline"
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
      </div>
    );
  }
}

export default ReCaptchav2;
