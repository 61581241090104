import React from 'react';
import './index.scss';

export default class FlexView extends React.Component {
  render() {
    const { brand } = this.props;
    return (
      <>
        <div className="wrapper" id="flex-view-brand">
          {brand.map(data => {
            return (
              <div className="brand" key={data.id}>
                <img
                  src={require(`../../images/brand/${data.id}.png`)}
                  alt={data.id}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
