import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

export default class FooterNav extends React.Component {
  constructor(props) {
    super(props);
    //this.touchHandler = this.props.touchHandler.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.state = {
      ArchIcon: this.props.ArchIcon,
      footerContent: this.props.footerContent,
      contactUsIsClicked: false,
      sitemapIsClicked: false
    };
  }

  toggleClass = ele => {
    let contentType;
    if ('sitemap' === ele.target.dataset.type) {
      contentType = this.state.sitemapIsClicked;
      this.setState({ sitemapIsClicked: !contentType });
    } else {
      contentType = this.state.contactUsIsClicked;
      this.setState({ contactUsIsClicked: !contentType });
    }
  };

  render() {
    return (
      <div className="footerContent">
        <div className="table-cell col1">
          <img src={this.state.ArchIcon} alt="Arch | The full-service Digital Consultancy in Hong Kong" />
          <p>ARCH PARTNERSHIP LTD.</p>
        </div>
        <div className="table-cell col2">
          <nav className="footerNav">
            <div className="column-section">
              <ul className={this.state.contactUsIsClicked ? 'open' : ''}>
                <li className="desktop">
                  {this.state.footerContent.contactus.id}
                </li>
                <li
                  className={'mobile'}
                  data-type="contactus"
                  onClick={this.toggleClass}>
                  {this.state.footerContent.contactus.id}
                  <i className="expand-icon fa fa-plus" data-type="contactus" />
                </li>
                {this.state.footerContent.contactus.content.map(
                  (data, dataIndex) => {
                    return (
                      <li
                        className={`contact-us${
                          dataIndex === 1 ? ' extra-padding' : ''
                        }`}
                        key={data.id}>
                        <img
                          src={require('../../images/' + data.icon)}
                          alt={data.id}
                        />
                        <p dangerouslySetInnerHTML={{ __html: data.value }}></p>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
            <div className="column-section">
              <ul className={this.state.sitemapIsClicked ? 'open' : ''}>
                <li className="desktop">
                  {this.state.footerContent.sitemap.id}
                </li>
                <li
                  className="mobile"
                  data-type="sitemap"
                  onClick={this.toggleClass}>
                  {this.state.footerContent.sitemap.id}
                  <i className="expand-icon fa fa-plus" data-type="sitemap" />
                </li>
                {this.state.footerContent.sitemap.content.map(data => {
                  return (
                    <li
                      className="sitemap"
                      key={data.id}
                      data-id={data.id}
                      data-content={'footer'}
                      //onTouchStart={this.touchHandler}
                      //onTouchEnd={this.touchHandler}
                    >
                      <a
                        className={data.isLinkClicked ? 'touch' : ''}
                        href={data.value}
                        data-type="sitemap"
                        onClick={this.toggleClass}>
                        {data.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>
        <div className="copyright-terms-conditions-wrapper">
          <span>Copyright @ {new Date().getFullYear()}</span>
          {/* <span><a href="/#!/policy">Terms & Conditions</a></span>
					<span><a href="/#!/cookie-policy">Cookie Policy</a></span> */}
        </div>
      </div>
    );
  }
}

// for the DOM rendor the component
// export default ServiceBoard;
