import React from 'react';
import ServiceBoard from '../../components/service/serviceBoard';
import uuid from 'uuid';
import Slider from '../../components/slider';
import SliderShow from '../../components/slideShow';
import About from '../about';
import FlexView from '../../components/flexView';
import LocalData from '../../data/home/index.js';
import ParticlesConfig from '../../data/home/particles-config.json';
import Particles from 'react-particles-js';
import CardItem from '../../components/cardItem';
import ContactUs from '../contact-us';
import SectionItem from '../../components/sectionItem';
import './index.scss';
import FlightPlane from '../../components/flightPlane';
import arrowImg from '../../images/arrow-right.png';
import { Parallax } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet';
import Config from '../../config';

import { Elastic } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'TimelineMax';
import 'animation.gsap';
import 'debug.addIndicators';

function scrollAnimation() {
  let controller = new ScrollMagic.Controller();
  // eslint-disable-next-line no-undef
  let tl = new TimelineMax();
  tl.staggerFrom('.icon-list img', 2, {
    scale: 0,
    cycle: {
      y: [-50, 50],
    },
    ease: Elastic.easeOut,
    stagger: {
      from: 'center',
      amount: 0.25,
    },
  });

  // eslint-disable-next-line no-undef
  let t2 = new TimelineMax();
  t2.from('.icon-list p', 2, { x: -300, opacity: 0 });

  if (document.getElementById('service')) {
    let scene = new ScrollMagic.Scene({
      triggerElement: '#service',
      triggerHook: 0,
    })
      .setTween(tl)
      .addTo(controller);

    let scene2 = new ScrollMagic.Scene({
      triggerElement: '#service',
      duration: '50%',
      triggerHook: 0.5,
    })
      .setTween(t2)
      .addTo(controller);
  }
}

export default class Home extends React.Component {
  readJsonData = () => {
    const { workSlides, newsSlides, services, brand, service } = LocalData;
    let slidesData = [];
    let servicesData = [];
    let workSlidesData = [];
    workSlides.content.map((data) => {
      return workSlidesData.push({
        id: uuid(),
        ...data,
      });
    });
    newsSlides.content.map((data) => {
      return slidesData.push({
        id: uuid(),
        ...data,
      });
    });
    services.map((data) => {
      return servicesData.push({
        id: uuid(),
        isHover: false,
        ...data,
      });
    });
    return {
      slides: slidesData,
      slides_path: newsSlides.path,
      services: servicesData,
      brand: brand,
      service: service,
      workSlides: workSlidesData,
    };
  };

  constructor(props) {
    super(props);

    this.state = {
      browserSize: {
        width: window.innerWidth,
      },
      ...this.readJsonData(),
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    const { browserSize } = this.state;
    let browserSizeTemp = browserSize;
    let update_width = window.innerWidth;
    browserSizeTemp.width = update_width;
    this.setState({ browserSize });
  }

  componentDidMount() {
    scrollAnimation();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  // news
  /*switchNewsHander = (changeContent) => {
			let { newsContents } = this.state;
		  
			let newsContent = newsContents.find(newsContent => newsContent.contents === 'react app 1' ? newsContent : '');
			if (newsContent)
				newsContent.contents = changeContent;
			// change the state value or object
			this.setState({newsContents});
		  
	}
  
	addNewsHandler = () => {
			this.setState({
					newsContents : [
							...this.state.newsContents,
							...this.state.extraNews,
					]
			});
	}

	resetNewsHandler = () => {
			const resetState = this.initial_state();
			this.setState({
					...resetState
			});
	}*/
  // news

  // service
  /*clickServiceHandler = (id) => {
			const { services } = this.state;
			const service = services.find(service => service.id === id);
			service.isCompleted = !service.isCompleted;
			this.setState({ services });
	}*/
  // service

  mouseHandler = (id) => {
    const { services } = this.state;
    const service = services.find((service) => service.id === id);
    service.isHover = !service.isHover;
    this.setState({ services });
  };

  onclickHandler = () => {
    const wrapper = document.getElementsByClassName('particles_container');
  };

  render() {
    const { workSlides, services, browserSize, brand, service } = this.state;
    const meta_data = Config.helmet.meta.find((meta_data) => meta_data.id === 'home');
    return (
      <>
        <Helmet>
          <meta charSet={Config.helmet.charset} />
          <title>{meta_data.title}</title>
          <meta name="Description" content={meta_data.description} />
          <link rel="canonical" href={Config.helmet.pathname + meta_data.canonical} />
          {/* Part of og tag */}
          <meta property="og:title" content={meta_data.title} />
          <meta property="og:description" content={meta_data.description} />
          <meta property="og:url" content={Config.helmet.pathname + meta_data.canonical} />
          <meta property="og:image" content={Config.helmet.siteImage} />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="800" />
          <meta property="og:type" content="website" />
          {/* Part of og tag */}
        </Helmet>
        <SectionItem
          id="slider"
          onclickHandler={this.onclickHandler}
          children={
            <div className="home-top">
              <Particles className="particles_container" params={ParticlesConfig} />
              <div className="overlay-content">
                <div className="tagline style2">
                  <Parallax className="parallax-tagline" x={[-50, 0]}>
                    <span className="top">
                      <span className="stroke">iness</span>Technology Innovation
                      <span className="stroke">Transfor</span>
                    </span>
                  </Parallax>
                  <Parallax className="parallax-tagline" x={[0, -20]}>
                    <span className="middle">
                      <span className="stroke">logyInnovation</span>
                      Transforming
                      <span className="stroke">Business</span>
                    </span>
                  </Parallax>
                  <Parallax className="parallax-tagline" x={[-20, 0]}>
                    <span className="bottom">
                      <span className="stroke">transforming</span>Business
                      <span className="stroke">Technology</span>
                    </span>
                  </Parallax>
                </div>
              </div>
            </div>
          }
          scrollTo="about"
          scrollDown={this.props.scrollDown}
        />
        <div className="background-triangle-wrapper">
          <div className="background-triangle"></div>
        </div>
        <SectionItem id="about" children={<About />} scrollTo="service" scrollDown={this.props.scrollDown} />

        <SectionItem
          id="service"
          children={
            <>
              <h2 className="stroke">OUR SERVICES-</h2>
              <div className="icon-list">
                <ul>
                  {services.map((data, i) => {
                    return (
                      <li key={`serice_item_${data.title}`}>
                        <img src={data.img} alt={`${data.title} Icon`} key={`serice_image_${data.title}`} />
                        <p key={`serice_title_${data.title}`}>{data.title}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          }
          scrollTo="featureWork"
          scrollDown={this.props.scrollDown}
        />
        <SectionItem id="featureWork" children={<Slider slides={workSlides} />} scrollTo="client" scrollDown={this.props.scrollDown} />

        <SectionItem
          id="client"
          children={
            <>
              <h2 className="stroke">OUR CLIENTS-</h2>
              <div>
                <FlexView brand={brand}></FlexView>
              </div>
            </>
          }
          scrollTo="contact-us"
          scrollDown={this.props.scrollDown}
        />

        {/* <div className="section" id="scroll">
						<h1>Testing scrolling animation</h1>
						<ScatterItem/>
				</div> */}
        {/* <div className="section" id="new">
						<News 
								contents="react app" 
								date={new Date().toLocaleString()}>What's NEW</News>
								{newsContents.map((data) => {
										return <News 
												key={data.id} 
												contents={data.contents} 
												date={data.date}/>
								})}
								{newsContents.length > 1 ? <span className="next spanBtn" onClick={this.switchNewsHander.bind(this, 'react app 3')}>next</span> : ''}
								<span className="add spanBtn" onClick={this.addNewsHandler}>add</span>
								<span className="reset spanBtn" onClick={this.resetNewsHandler}>reset</span>
				</div> 
			
			<SectionItem
          id="new"
          children={<Slider slides={slides} path={slides_path} />}
          scrollTo="shareAndJoinUs"
          scrollDown={this.props.scrollDown}
        />*/}

        {/* <div className="section" id="service">
						<ServiceBoard
								services={services}
								service_row={(browserSize.width > 1200 ? 2 : 4)}
								mouseHandler={this.mouseHandler}
						/>
				</div> 
				*/}

        {/* <SectionItem
          id="shareAndJoinUs"
          children={
            <>
              <FlightPlane />
              <div>
                 //Icons made by Freepik from www.flaticon.com 
                <h1 className="stroke black">GOT A PROJECT IN MIND?</h1>
                <div
                  className="btn arrow"
                  onClick={() => {
                    var ele = document.getElementById('contact-us');
                    if (ele) {
                      window.scrollTo(0, ele.offsetTop);
                    }
                  }}>
                  <div className="btn-content">
                    <p>
                      SEND US A MESSAGE
                      <img className="btn-arrow" src={arrowImg} />
                    </p>
                  </div>
                </div>
              </div>
            </>
          }
        /> */}

        <SectionItem id="contact-us" children={<ContactUs />} />
      </>
    );
  }
}
