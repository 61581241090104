import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App, app } from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ScrollIntoView from './components/scrollIntoView';
import { ParallaxProvider } from 'react-scroll-parallax';

const appEle = (
  /* https://www.arch-partnership.com */
  <BrowserRouter basename="/">
    <ScrollIntoView>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </ScrollIntoView>
  </BrowserRouter>
);

ReactDOM.render(appEle, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
