import React from 'react';
import './index.scss';

export default class About extends React.Component {
  showMore() {
    var story = document.getElementById('about-content');
    story.classList.add('open');
  }

  render() {
    return (
      <>
        <h2 className="stroke">ABOUT US-</h2>
        <div className="about-content-container">
          <div className="about-content" id="about-content">
            <p>
              We are groups of technology enthusiasts who fancy technology
              innovation and future. In 2012, we met and founded ARCH, where we
              connect all individuals intelligently to create values. Since
              then, we have been based in Hong Kong and have acted as a bridge
              between enterprises and users. We have aimed to make our clients’
              needs <span className="highlight stroke">A</span>
              chievable by providing <span className="highlight stroke">R</span>
              eliable and <span className="highlight stroke">C</span>ustomized
              solutions with our <span className="highlight stroke">H</span>
              onesty.
            </p>
            <div>
              <p>
                We connect our clients, we conduct Research, Design, Development
                and Delivery. Our solutions and products have been developed
                with scalability as top of mind to cope with continuous
                enhancements and customizations which have helped us address our
                customer's needs more. To fulfill our clients’ O2O business for
                B2B & B2C users, we have found that our solutions covering the
                whole spectrum of digital transformation from UI/UX Design and
                Development, Web and Mobile app Development, Enterprises System
                Customization & Integration, eCommerce, Front-end to Backend
                development , Data Analytics , to Intelligent Marketing
                Strategy, SEO and Brand Identity Design.
              </p>
              <p>
                ARCH’s knowledge includes managing and delivering various market
                leading applications which have been recognized by our clients
                from various industries such as banking & insurance industry,
                medical & health industry, retails industry, etc. We focus on
                Results and Impact. Most of our clients are repeated customers,
                which is the best testament to ARCH team's quality of work and
                business maturity. We believe, it’s the result of “Together
                Everyone Achieves More” and “We Grow as Our Clients Grow”.
              </p>
            </div>
          </div>
          <div className="about-btn" onClick={this.showMore}>
            SHOW OUR STORY
          </div>
        </div>
      </>
    );
  }
}
