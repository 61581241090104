import React from 'react';
import './index.scss';
import config from '../../config';
import ReCaptchav2 from '../../components/recaptcha';
import * as EmailValidator from 'email-validator';
import Axios from 'axios';
import { hasClass } from '../../util';

Object.assign(Axios.defaults, {
  // baseURL: config.endpointUrl,
  baseURL: config.apiEndpoint,
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  responseType: 'json',
  transformResponse: function(data) {
    return data && typeof data === 'string' ? JSON.parse(data) : data || {};
  }
});

function post(...args) {
  return Axios.post(...args)
    .then(({ data: { success, ...rest } }) => rest)
    .catch(error => {
      if (!error.status) {
        return Promise.reject(error);
      }
    });
}

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: '',
        email: '',
        message: '',
        recaptcha: '',
        host: 'a'
      },
      isSubmitting: false,
      isError: false,
      isSuccess: false
    };
    this.captcha = null;
    this._onSuccess = this._onSuccess.bind(this);
    this._onError = this._onError.bind(this);
    this.getResponse = this.getResponse.bind(this);
    this.verifyFormValues = this.verifyFormValues.bind(this);
  }
  componentDidMount() {}

  componentWillUnmount() {
    this._onSuccess = function() {};
    this._onError = function() {};
  }
  getResponse = token => {
    this.setState({
      values: { ...this.state.values, recaptcha: token }
    });
  };

  handleInputChange = e => {
    var nameEle = document.getElementById('input_name');
    var emailEle = document.getElementById('input_email');
    var border = document.getElementById('g-recaptcha');
    if (hasClass(border, 'error')) {
      border.classList.remove('error');
    }

    if (hasClass(emailEle, 'error')) {
      emailEle.classList.remove('error');
      var pChildEmail = emailEle.getElementsByTagName('p')[0];
      emailEle.removeChild(pChildEmail);
    }

    if (hasClass(nameEle, 'error')) {
      nameEle.classList.remove('error');
      var pChildName = nameEle.getElementsByTagName('p')[0];
      nameEle.removeChild(pChildName);
    }

    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value }
    });
  };
  verifyFormValues = data => {
    var nameEle = document.getElementById('input_name');
    var emailEle = document.getElementById('input_email');
    let p_name = document.createElement('p');
    let p_email = document.createElement('p');
    let valid = true;
    if (data.name === undefined || data.name === null || data.name === '') {
      if (!hasClass(nameEle, 'error')) {
        nameEle.classList.add('error');
        var m = document.createTextNode('Please enter your name');
        p_name.appendChild(m);
        nameEle.appendChild(p_name);
      }
      valid = false;
    }

    if (data.email !== undefined && data.email !== null && data.email) {
      if (!EmailValidator.validate(data.email)) {
        if (!hasClass(emailEle, 'error')) {
          emailEle.classList.add('error');
          var t = document.createTextNode('Incorrect email format');
          p_email.appendChild(t);
          emailEle.appendChild(p_email);
        }
        valid = false;
      }
    } else {
      if (!hasClass(emailEle, 'error')) {
        emailEle.classList.add('error');
        var e = document.createTextNode('Please enter email address');
        p_email.appendChild(e);
        emailEle.appendChild(p_email);
      }
      valid = false;
    }

    if (this.state.values.recaptcha === '') {
      var border = document.getElementById('g-recaptcha');
      if (!hasClass(border, 'error')) {
        border.classList.add('error');
      }

      valid = false;
    }
    return valid;
  };

  submit = param => {
    return post('/form-to-email', param);
  };

  begin(promise) {
    this.setState({
      isSubmitting: true
    });

    promise.then(
      data => this._onSuccess(data),
      error => this._onError(error)
    );
    return promise;
  }

  _onSuccess(d) {
    this.setState({
      isSuccess: d.result,
      isSubmitting: false,
      isError: !d.result ? d : false
    });
  }

  _onError(error) {
    if (error.response === undefined) {
      this.setState({
        isSubmitting: false,
        isSuccess: false,
        isError: {
          message: error,
          code: false
        }
      });
    } else {
      this.setState({
        isSubmitting: false,
        isSuccess: false,
        isError: {
          message: error,
          code: error.response.data.Code
        }
      });
    }
  }

  submitForm = e => {
    e.preventDefault();

    if (this.verifyFormValues(this.state.values)) {
      this.begin(this.submit(this.state.values));
    }
  };

  render() {
    var { isSubmitting, isSuccess, isError } = this.state;

    let overlay;

    if (isSubmitting) {
      overlay = (
        <div className="pending_container">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    } else if (isSuccess) {
      overlay = (
        <div className="pending_container">
          <p className="notification">Email sent! Let's keep in touch.</p>
        </div>
      );
    } else if (isError) {
      overlay = (
        <div className="pending_container">
          <p className="notification">
            Unknown error occurred! Please try again later, <br />
            or send us a message via info@arch-partnership.com
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="container">
          <h2 className="stroke black">GOT A PROJECT IN MIND?</h2>
          <p>Fill out the form or email us at info@arch-partnership.com </p>
          <p>We'll get back to you as soon as possible</p>

          <form
            className="contactUsForm"
            id="contactUsForm"
            onSubmit={this.submitForm}>
            <div className="input_container" id="input_name">
              <input
                type="text"
                name="name"
                placeholder="Your name"
                value={this.state.values.name}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="input_container" id="input_email">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.values.email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="input_container" id="input_message">
              <input
                type="text"
                name="message"
                placeholder="Tell us about your project"
                value={this.state.values.message}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="recaptcha" key="recaptcha_v2">
              <ReCaptchav2
                getResponse={this.getResponse}
                ref={el => {
                  this.captcha = el;
                }}
              />
            </div>
            <input type="submit" value="SEND" />
            {overlay}
          </form>
        </div>
      </>
    );
  }
}
