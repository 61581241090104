import React from 'react';
import './index.scss';
import NewsData from '../../data/news';
import {
  EmailShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  TelegramShareButton
} from 'react-share';
import { Link } from 'react-router-dom';

const findNewContent = number => {
  const { news } = NewsData;
  return news.find(newContent => newContent.number === number);
};
// functional component
// dynamic contents
const news = props => {
  let newContent = findNewContent(props.match.params.id);
  let number = parseInt(props.match.params.id);
  const { news } = NewsData;
  let lastNumber = news.length;
  return (
    <div className="newsWrapper">
      {newContent ? (
        <>
          <div className="share-wrapper">
            <EmailShareButton
              className="share-icon"
              size={32}
              round={true}
              url={props.location.pathname}>
              <span>email</span>
            </EmailShareButton>
            <WhatsappShareButton
              className="share-icon"
              size={32}
              round={true}
              url={props.location.pathname}>
              <span>Whatsapp</span>
            </WhatsappShareButton>
            <FacebookShareButton
              className="share-icon"
              size={32}
              round={true}
              url={props.location.pathname}>
              <span>Facebook</span>
            </FacebookShareButton>
            <TelegramShareButton
              className="share-icon"
              size={32}
              round={true}
              url={props.location.pathname}>
              <span>Telegram</span>
            </TelegramShareButton>
          </div>
          <div className="image-wrapper"></div>
          <div className="content-wrapper">
            <h1>{newContent.title ? newContent.title : ''}</h1>
            <h2>
              {newContent.date ? newContent.date : new Date().toDateString()}
            </h2>
            <h3>
              {newContent.content
                ? newContent.content
                : 'The content for New is missing'}
            </h3>
          </div>
          <div className="news-Control">
            {number === 1 ? (
              ''
            ) : (
              <Link className="pre" to={'/news/new' + (number - 1)}>
                &#8249;
              </Link>
            )}
            {number === lastNumber ? (
              ''
            ) : (
              <Link className="next" to={'/news/new' + (number + 1)}>
                &#8250;
              </Link>
            )}
          </div>
        </>
      ) : (
        <>
          <div>This New may be not exist</div>
        </>
      )}
    </div>
  );
};

export default news;
