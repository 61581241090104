import workdetails from './data/works';

const routes = [
  {
    "path": "/",
    "component": "Home",
    "exact": true
  },
  {
    "path": "/work-number/:number/:id",
    "component": "WorkDetail",
    "exact": false
  }
];

// part for generating sitemap config
let work_detail_params = [];
workdetails.map((detail, index) => {
  let work_number = index + 1;
  work_detail_params.push({
    number: work_number < 10 ? '0' + work_number : work_number,
    id: detail.id
  });
})

const sitemapParamsConfig = {
  "/work-number/:number/:id": work_detail_params
}

export {
  routes,
  sitemapParamsConfig
}

