import React from 'react';
import './index.scss';
import InlineSVG from 'svg-inline-react';

const headerOffset = 98;
let count = 1;

export default class scrollNextStepButton extends React.Component {
  constructor(props) {
    super(props);

    let archTextIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="150px" height="150px" viewBox="0 0 300 300" id="svg${count}">
        <defs>
            <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "/>
        </defs>
        <circle class="arch-circle" cx="150" cy="150" r="40" fill="white"/>
        <text fill="#000">
          <textPath xlink:href="#circlePath">ARCH</textPath>
        </text>
      </svg>`;

    count++;
    this.state = {
      scrollTo: this.props.scrollTo,
      archTextIcon: archTextIcon,
    };
  }

  scrollDown = () => {
    let target = document.getElementById(this.state.scrollTo);
    window.scroll({ top: target.offsetTop, behavior: 'smooth' });
  };

  render() {
    // https://blog.lftechnology.com/using-svg-icons-components-in-react-44fbe8e5f91 to generate svg in react
    return (
      <>
        <InlineSVG className="svg-div" src={this.state.archTextIcon} onClick={this.scrollDown} />
        {/* <i className="fa fa-angle-double-down" onClick={() => this.scrollDown(scrollToSetup)}></i> */}
        {/* <div className="scrollNextStepButtonWrapper" onClick={() => this.scrollDown(scrollToSetup)}>
          <i className="fa fa-angle-double-down"></i>
        </div> */}
      </>
    );
  }
}
