import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollIntoView extends PureComponent {
  // componentDidMount = () => window.scrollTo(0, 0);

  componentDidUpdate = prevProps => {
    if (this.props.location.pathname !== prevProps.location.pathname)
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
  };

  render = () => this.props.children;
}

export default withRouter(ScrollIntoView);
