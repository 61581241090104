const workdetails = require('../works');
let workSlideContents = [];
workdetails.map((detail) => {
  workSlideContents.push({
    title: detail.data.work_type,
    img: require(`../../images/${detail.work_demon}`),
    link: detail.id
  });
})

module.exports = {
  workSlides: {
    path: '',
    content: workSlideContents
  },
  newsSlides: {
    path: '/news/new',
    content: [
      {
        title: 'REACT1',
        description: 'REACT1 description',
        img: './image/logo-white.png'
      },
      {
        title: 'REACT2',
        description: 'REACT2 description',
        img: './image/logo-white.png'
      },
      {
        title: 'REACT3',
        description: 'REACT3 description',
        img: './image/logo-white.png'
      },
      {
        title: 'REACT4',
        description: 'REACT4 description',
        img: './image/logo-white.png'
      },
      {
        title: 'REACT5',
        description: 'REACT5 description',
        img: './image/logo-white.png'
      }
    ]
  },
  services: [
    {
      title: 'Web & Mobile\nDevelopment',
      img: require('../../images/services/web-app.svg')
    },
    {
      title: 'UI/UX Design',
      img: require('../../images/services/uiux-design.svg')
    },
    {
      title: 'Custom-made\nSolution',
      img: require('../../images/services/custom-made.svg')
    },
    {
      title: 'Front-to-back end Development',
      img: require('../../images/services/icon_front_back_end.png')
    },
    {
      title: 'Data Analytics',
      img: require('../../images/services/data-analytics.svg')
    },
    {
      title: 'Digital Marketing&\nSEO',
      img: require('../../images/services/degital-marketing.svg')
    },
    {
      title: 'AR + VR Experience',
      img: require('../../images/services/ar-vr.svg')
    },
    {
      title: 'Brand Identity Design',
      img: require('../../images/services/branding.svg')
    }
  ],
  brand: [
    {
      id: 'brand_prudential',
      img: 'brand_prudential.png'
    },
    {
      id: 'brand_nike',
      img: 'brand_nike.png'
    },
    {
      id: 'brand_ocbc',
      img: 'brand_ocbc.png'
    },
    {
      id: 'brand_whirlpool',
      img: 'brand_whirlpool.png'
    },
    {
      id: 'brand_chubb',
      img: 'brand_chubb.png'
    },
    {
      id: 'brand_sompo',
      img: 'brand_sompo.png'
    },
    {
      id: 'brand_hkjc',
      img: 'brand_hkjc.png'
    },
    {
      id: 'brand_lego',
      img: 'brand_lego.png'
    },
    {
      id: 'brand_tictac',
      img: 'brand_tictac.png'
    },
    {
      id: 'brand_fdw',
      img: 'brand_fdw.png'
    },
    {
      id: 'brand_caltrate',
      img: 'brand_caltrate.png'
    },
    {
      id: 'brand_brooks_brother',
      img: 'brand_brooks_brother.png'
    },
    {
      id: 'brand_aveeno',
      img: 'brand_aveeno.png'
    },
    {
      id: 'brand_kinder',
      img: 'brand_kinder.png'
    },
    {
      id: 'brand_souvenaid',
      img: 'brand_souvenaid.png'
    }
  ],
  service: {
    id: 'service',
    content: [
      {
        img: 'icon_development',
        title: 'DEVELOPMENT',
        alt: 'development',
        descriptions: [
          'More than just usability.',
          'We convey all touchpoints and',
          'interactions in an artsy way.'
        ]
      },
      {
        img: 'icon_marketing',
        title: 'MARKETING',
        alt: 'marketing',
        descriptions: [
          'We analyze, forecast and',
          'optimize, making insights',
          'really happen.'
        ]
      },
      {
        img: 'icon_design',
        title: 'DESIGN',
        alt: 'design',
        descriptions: [
          'Resonantly communicate in a',
          'landscape that is digitally-led. We make',
          'brands impossible to be ignored.'
        ]
      }
    ]
  }
};
