import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

export default class MoreWork extends React.Component {
  render() {
    const { work } = this.props;
    let work_demo_img = '';
    try {
      work_demo_img = require(`../../../images/${work.work_demon}`);
    } catch (e) {
      work_demo_img = require(`../../../images/works/work_illustration.jpg`);
    }

    const formattedNumber = work.number < 10 ? '0' + work.number : work.number;

    return (
      <>
        <div className="slider-image">
          <img src={work_demo_img} alt={work.work_type} />
        </div>
        <a href={`#!/work-number/${formattedNumber}/${work.id}`}>
          <div className="slider-content">
            <div className="slider-index">
              <span>{formattedNumber}</span>
            </div>
            <h3 className="slider-title">{work.work_type}</h3>
          </div>
        </a>
      </>
    );
  }
}
