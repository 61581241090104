import React from 'react';
import './index.scss';
import cases from '../../data/works';
import MoreWork from './moreWork';
import Swiper from 'react-id-swiper';
import arrowImg from '../../images/arrow-right.png';
import 'swiper/css/swiper.css';
import YouTube from 'react-youtube';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import config from '../../config';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

const defaultSetting = {
  more_work_number: 4,
  more_work_col: 2,
};

var facebookShare = (link, type) => {
  switch (type) {
    case 'video':
      return (
        <div className="facebook-video">
          <FacebookProvider appId={config.appId}>
            <EmbeddedPost href={link} width="700" height="200" />
          </FacebookProvider>
        </div>
      );
    case 'post':
      return (
        <div className="facebook-post">
          <FacebookProvider appId={config.appId}>
            <EmbeddedPost href={link} width="auto" />
          </FacebookProvider>
        </div>
      );
    default:
      return <></>;
  }
};

var caseContent = (content, contentIndex) => {
  return (
    <>
      {content.picture ? (
        <div></div>
      ) : (
        <>
          <div className={`body-row ${content.length === contentIndex + 1 ? 'extra-padding-bottom' : ''}`}>
            <div className="body-title">
              {content.titles.map((title, titleIndex) => {
                return (
                  <>
                    <h1 key={`title-${titleIndex}`}>{title}</h1>
                    {content.titles.length === titleIndex + 1 ? '' : <br />}
                  </>
                );
              })}
            </div>
            <div className="body-content">
              <ul>
                {content.descriptions.map((description, descriptionIndex) => {
                  return <li className={`${description.class}`}>{description.text}</li>;
                })}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const renderDetail = (cases, work_demon, number, moreWorks) => {
  let work_demo_img = '';
  try {
    work_demo_img = require(`../../images/${work_demon}`);
  } catch (e) {
    work_demo_img = require(`../../images/works/work_illustration.jpg`);
  }

  const params = {
    slidesPerView: 4,
    grabCursor: true,
    loop: true,
    spaceBetween: 30,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1.5,
        spaceBetween: 10,
      },
    },
  };

  const configVidOpts = (index) => {
    let vidOpts = {
      height: '390',
      width: '640',
      className: 'content-youtube',
      playerVars: {
        autoplay: index < 1 ? 1 : 0,
      },
    };
    return vidOpts;
  };

  return (
    <>
      <div className={`work_detail_container ${cases.brand_color_class}`}>
        <div className="brand-detail">
          <div className="brand-img">
            <img className="brand_case_img bg_white" src={work_demo_img} alt={cases.brand_name} />
          </div>
          <div className="brand-info">
            <img
              className={`brand_img circle_brand_icon ${cases.brand_icon_class}`}
              src={require(`../../images/${cases.brand_icon}`)}
              alt={cases.brand_name}
            />
            <div className="brand-title">
              <h1>{cases.work_type}</h1>
            </div>
            <div className="brand-tags">
              {cases.work_tags.map((tag, tagIndex) => {
                return (
                  <div className="tag" key={`brand_tags_${tagIndex}`}>
                    {tag}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={`work_detail_container bg_black`}>
        <div className="brand-body center_div font_white">
          <span className="brand-number">{number}</span>

          {cases.body.map((section, sectionRowIndex) => {
            return (
              <div className="content-row" key={`content-row-${sectionRowIndex}`}>
                {section.contents.map((content, contentIndex) => {
                  return caseContent(content, contentIndex);
                })}
                <div className="videos-container">
                  {section.videos.map((vid, i) => {
                    if (vid.type === 'youtube') {
                      return (
                        <div className="content-vid" key={vid.id}>
                          <YouTube videoId={vid.id} opts={configVidOpts} />
                        </div>
                      );
                    } else if (vid.type === 'facebook') {
                      return facebookShare(vid.link, 'video');
                    } else if (vid.type === 'local_video') {
                      return <ReactPlayer className="work_video" controls={true} playing={i < 1 ? true : false} alt={vid.title} url={vid.src} />;
                    }
                  })}
                </div>
                <div className="fbPost-container">
                  {section.fbPost.map((link, i) => {
                    return facebookShare(link, 'post');
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`work_detail_container bg_white`}>
        <div className="more_work center_div white worksSlider">
          <h1>More Works</h1>
          <>
            <Swiper {...params}>
              {moreWorks.map((moreWork, moreWorkIndex) => {
                return <div key={`moreWorkIndex${moreWorkIndex}`}>{moreWork}</div>;
              })}
            </Swiper>
          </>
        </div>
        <div className="contact-us">
          <h1>Interested to know more?</h1>
          <div className="btn arrow">
            <div className="btn-content">
              <a href="/#!/#contact-us">
                <p>
                  SEND US A MESSAGE
                  <img className="btn-arrow" src={arrowImg} alt="contact us" />
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default class workDetail extends React.Component {
  findContinuousCase = (data, targetIndex, number) => {
    let row_content = [];
    for (let i = 1; i < data.length + 1; i++) {
      number = number % data.length;
      number++;
      let work = {
        id: data[(targetIndex + i) % data.length].id,
        work_demon: data[(targetIndex + i) % data.length].work_demon,
        work_type: data[(targetIndex + i) % data.length].data.work_type,
        number: number,
      };
      row_content.push(<MoreWork work={work} />);
    }
    return row_content;
  };

  componentDidMount() {
    let header_append = document.getElementById('header_append');
    let headerNav = document.getElementsByClassName('headerNav');
    if (header_append && headerNav.length > 0 && headerNav[0].offsetHeight > 0) {
      header_append.style.height = headerNav[0].offsetHeight + 'px';
    }
  }

  render() {
    //TODO : handle no this id
    let detail = cases.find((workDetailsData) => workDetailsData.id === this.props.match.params.id);
    let detailNo = cases.findIndex((workDetailsData) => workDetailsData.id === this.props.match.params.id);
    let moreWorks = this.findContinuousCase(cases, detailNo, this.props.match.params.number);
    let meta_data = config.helmet.meta.find((meta_data) => meta_data.id === this.props.match.params.id);
    return (
      <>
        <Helmet>
          <meta charSet={config.helmet.charset} />
          <title>{meta_data.title}</title>
          <meta name="Description" content={meta_data.description} />
          <link rel="canonical" href={config.helmet.pathname + meta_data.canonical} />
          {/* Part of og tag */}
          <meta property="og:title" content={meta_data.title} />
          <meta property="og:description" content={meta_data.description} />
          <meta property="og:url" content={config.helmet.pathname + meta_data.canonical} />
          <meta property="og:image" content={config.helmet.siteImage} />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="800" />
          <meta property="og:type" content="website" />
          {/* Part of og tag */}
        </Helmet>
        {renderDetail(detail.data, detail.work_demon, this.props.match.params.number, moreWorks)}
      </>
    );
  }
}
